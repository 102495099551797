import React from "react";
import ToggleButton from "../buttons/ToggleButton";
import EditButton from "../buttons/EditButton";
import DelButton from "../buttons/DelButton";
import {createCourse, updateCourse} from "../../api_connectors/crmApiConnector";
import {useDrag, useDrop} from "react-dnd";
import UploadButton from "../buttons/UploadButton";

function Kurs({kursItem, setEditModalItem, setUploadModalItem, setDeleteModalItem, dropItemToSibling, dropItemToContainer, updateData}) {
    const [{isDragging}, drag] = useDrag(
        () => ({
            type: 'kurs',
            item: {
                type: 'kurs',
                id: kursItem.id,
                item: kursItem,
            },
            collect: (monitor) => ({
                isDragging: !!monitor.isDragging(),
            })
        }),
        []
    );
    const [{isOver, canDrop}, drop] = useDrop({
        accept: ['kurs', 'neuer_kurs', 'evento_kurs'],
        drop: async (item, monitor) => {
            if (item.type === 'kurs') {
                await dropItemToSibling(item, kursItem);
            }
            if (item.type === 'neuer_kurs') {
                return await createCourse({
                    parent_category_id: kursItem.parent_category_id,
                    name: "Neuer Kurs",
                    active_flag: true,
                    html_text: "Neuer Kurs",
                    evento_id: null,
                }).then(() => {
                    updateData();
                });
            }
            if (item.type === 'evento_kurs') {
                return await createCourse({
                    parent_category_id: kursItem.parent_category_id,
                    name: item.kurs_name,
                    active_flag: true,
                    html_text: null,
                    evento_id: item.kurs_id,
                }).then(() => {
                    updateData();
                });
            }
        },
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
            canDrop: !!monitor.canDrop(),
        }),
    });

    if (kursItem.deleted) {
        return <></>;
    }

    const kursCallbackApiToggle = (courseItem) => {
        return async () => {
            courseItem.active_flag = !courseItem.active_flag;
            return await updateCourse(courseItem.id, courseItem);
        }
    };

    return (
        <>
            <div
                ref={(e) => { drag(e); drop(e); }}
                className={"planer-line planer-entry planer-entry-subpoint" + (canDrop ? " can-drop" : "") + (isOver ? " is-over" : "")}
                style={{ opacity: isDragging ? 0.5 : 1 }}
            >
                <p style={{maxWidth: 'calc(100% - 10rem)'}}>
                    {kursItem.evento_id && <img src="/bkd_logo.jpg" className={"planer-entry-evento-logo"}></img>}
                    {kursItem.file_name &&
                        <>
                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="fileIcon" width="1.2rem"
                                 height="1.2rem">
                                <path
                                    d="M14 2H6c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V8l-6-6zm1 7V3.5L18.5 9H15zm-5 2h6v2h-6v-2zm0 4h6v2h-6v-2z"
                                    fill="white" stroke="black" stroke-width="1"/>
                            </svg>
                            &nbsp;
                        </>
                    }
                    {kursItem.name || "(Kein Titel)"} {"(id: "+kursItem.id+")"}
                    <span className="planer-line-addition">
                        <ToggleButton isActive={kursItem.active_flag} callbackApiToggle={kursCallbackApiToggle(kursItem)}/>
                        <EditButton
                            callbackEdit={() => {
                                setEditModalItem(kursItem)
                            }}
                            canEdit={!kursItem.evento_id}
                        />
                        <UploadButton
                            callbackUpload={() => {
                                setUploadModalItem(kursItem)
                            }}
                        />
                        <DelButton callbackDelete={() => {
                            setDeleteModalItem(kursItem)
                        }}/>
                        </span>
                        </p>
                        </div>
                        </>
                        );
                    }

                    export default Kurs;
